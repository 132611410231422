import Modal from "react-bootstrap/Modal";
import { useState, useContext } from "react";
import { APIlogin } from "../service/UserService";
import { toast } from "react-toastify";
import { UserContext } from "../App";
function ModalLogin({ show, setShowModal }) {
    const { login, logout } = useContext(UserContext);
    let loginBtn = "btn login_btn";
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [passWordValid, setPassWordValid] = useState("login_valid");
    const [showPassword, setShowPassword] = useState(false);
    const [loadingAPI, setLoadingAPI] = useState(false);

    if (username && password && password.length > 5) {
        loginBtn = "btn login_btn active_btn";
    }
    const handleValidPassword = () => {
        if (password && password.length < 6) {
            setPassWordValid("login_valid invalid");
        } else {
            setPassWordValid("login_valid");
        }
    };
    const handleClose = () => {
        setShowModal(false);
        setPassword("");
    };
    const handleLogin = async () => {
        setLoadingAPI(true);
        if (!username || !password) {
            toast.error("missing username or password");
            return;
        }
        let res = await APIlogin(username.trim(), password);
        if (res && res.token) {
            login(username);
            localStorage.setItem("user", username);
            toast.success(`login success! Hello ${username}`);
        } else {
            if (res && res.status === 400) {
                logout();
                toast.error("login fail ! " + res.data.error);
                localStorage.setItem("user", "");
            }
        }
        setLoadingAPI(false);
        handleClose();
    };
    const handleEnter = (e) => {
        if (e.keyCode === 13 && password && password.length > 5) {
            handleLogin();
        }
    };
    return (
        <>
            <Modal show={show} onHide={handleClose} centered={true}>
                <Modal.Header closeButton>
                    <div className="login_title">
                        <Modal.Title>Log in</Modal.Title>
                    </div>
                </Modal.Header>
                <div className="login-container col-8">
                    {/* <h3 className="login_title">Login</h3> */}
                    <span className="Login_desc">Email or username</span>
                    <input
                        className="login_input"
                        type="text"
                        placeholder="Username"
                        value={username}
                        onChange={(e) => {
                            setUsername(e.target.value);
                        }}
                    />
                    <div
                        className="password_contain"
                        onBlur={handleValidPassword}
                    >
                        <input
                            className="login_input"
                            type={showPassword ? "text" : "password"}
                            placeholder="Password"
                            value={password}
                            onChange={(e) => {
                                setPassword(e.target.value);
                                if (e.target.value.length > 5) {
                                    setPassWordValid("login_valid");
                                }
                            }}
                            onKeyDown={handleEnter}
                        />
                        <div
                            className={
                                password ? "password_action" : "hidden_class"
                            }
                            onClick={() => {
                                setShowPassword(!showPassword);
                            }}
                        >
                            {showPassword ? (
                                <i className="fa-regular fa-eye"></i>
                            ) : (
                                <i className="fa-regular fa-eye-slash"></i>
                            )}
                        </div>
                    </div>
                    <span className={passWordValid}>
                        password must be have more than 6 character
                    </span>
                    <button className={loginBtn} onClick={handleLogin}>
                        <i
                            className={
                                loadingAPI
                                    ? "fa-solid fa-spinner fa-spin"
                                    : "hidden_class"
                            }
                        ></i>{" "}
                        Log in
                    </button>
                </div>
            </Modal>
        </>
    );
}

export default ModalLogin;
