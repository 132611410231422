import { useEffect, useState, useRef } from "react";
import Table from "react-bootstrap/Table";
import { fetchAllUser } from "../service/UserService";
import ReactPaginate from "react-paginate";
import ModalAddNew from "./ModalAddNew";
import { Container } from "react-bootstrap";
import ModalEditUser from "./ModalEditUser";
import ModalDeleteUser from "./ModalDeleteUser";
import _ from "lodash";
import Form from "react-bootstrap/Form";
import { debounce } from "lodash";
import { CSVLink } from "react-csv";
import Papa from "papaparse";
import { toast } from "react-toastify";
function TableUsers() {
    const [listUser, setListUser] = useState([]);
    // const [totalUser, setTotalUser] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [showModalAdd, setShowModalAdd] = useState(false);
    const [showModalEdit, setShowModalEdit] = useState(false);
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [dataUserEdit, setDataUserEdit] = useState({});
    let currentPage = useRef(1);
    //

    useEffect(() => {
        //call api
        getUsers();
    }, []);

    //
    const getUsers = async (page) => {
        let res = await fetchAllUser(page);
        if (res && res.data) {
            // setTotalUser(res.total);
            setTotalPage(res.total_pages);
            setListUser(res.data);
        }
    };
    const handleShowModalAdd = () => {
        setShowModalAdd(true);
    };
    const handlePageClick = (e) => {
        currentPage.current = +e.selected + 1;
        getUsers(currentPage.current);
    };
    const handleAddUser = (user) => {
        setListUser([user, ...listUser]);
    };
    const handleShowModalEdit = (user) => {
        setShowModalEdit(true);
        setDataUserEdit(user);
    };
    const handleUpdateUser = (user) => {
        let userListUpdate = [...listUser];
        userListUpdate = userListUpdate.map((item) =>
            item.id === user.id ? user : item
        );
        setListUser(userListUpdate);
    };
    const handleShowModalDelete = (user) => {
        setShowModalDelete(true);
        setDataUserEdit(user);
    };
    const handleDeleteUser = (id) => {
        let userListUpdate = [...listUser];
        userListUpdate = userListUpdate.filter((item) => item.id !== id);
        setListUser(userListUpdate);
        console.log(listUser);
    };
    const handleSort = (sortField, sortType) => {
        let cloneListUser = [...listUser];
        cloneListUser = _.orderBy(cloneListUser, [sortField], [sortType]);
        setListUser(cloneListUser);
    };
    ///search
    const handleSearch = debounce((e) => {
        let term = e.target.value;

        let cloneListUsers = [...listUser];
        if (term) {
            cloneListUsers = cloneListUsers.filter((user) =>
                user.email.includes(term)
            );
            setListUser(cloneListUsers);
        } else {
            getUsers(currentPage.current);
        }
    }, 1000);
    //// import
    const handleImportCVS = (e) => {
        if (e.target && e.target.files) {
            let file = e.target.files[0];
            if (file.type !== "text/csv") {
                toast.error("file import must be in csv format", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                return;
            }
            Papa.parse(file, {
                // header: true,
                complete: function (results) {
                    let rawData = results.data;
                    if (rawData.length > 0) {
                        if (rawData[0] && rawData[0].length === 4) {
                            if (
                                rawData[0][0] !== "Id" ||
                                rawData[0][1] !== "Email" ||
                                rawData[0][2] !== "First Name" ||
                                rawData[0][3] !== "Last Name"
                            ) {
                                toast.error("file import wrong header format");
                            } else {
                                let result = [];
                                rawData.forEach((item, index) => {
                                    if (index > 0 && item.length === 4) {
                                        let obj = {};
                                        obj.id = item[0];
                                        obj.email = item[1];
                                        obj.first_name = item[2];
                                        obj.last_name = item[3];
                                        result.push(obj);
                                    }
                                });
                                console.log(result);
                                setListUser(result);
                            }
                        } else {
                            toast.error("file import wrong format");
                        }
                    } else {
                        toast.error("file import is empty");
                    }
                },
            });
        }
    };
    //// export
    const headers = [
        { label: "Id", key: "id" },
        { label: "Email", key: "email" },
        { label: "First Name", key: "first_name" },
        { label: "Last Name", key: "last_name" },
    ];

    return (
        <>
            <Container>
                <div className="table-header my-3 d-sm-flex">
                    <span>
                        <b>List User</b>
                    </span>

                    <div className="table-action">
                        <label
                            htmlFor="importFile"
                            className="btn btn-warning"
                            style={{ color: "white" }}
                        >
                            <i class="fa-solid fa-file-import"> </i>
                            {" Import file"}
                        </label>
                        <input
                            type="file"
                            id="importFile"
                            hidden
                            onChange={handleImportCVS}
                        />
                        <CSVLink
                            data={listUser}
                            headers={headers}
                            filename={"my-file.csv"}
                            className="btn btn-primary"
                        >
                            <i class="fa-solid fa-file-export"> </i>
                            {" Export file"}
                        </CSVLink>

                        <button
                            className="btn btn-success"
                            onClick={handleShowModalAdd}
                        >
                            <i class="fa-solid fa-circle-plus"> </i>
                            {" Add new"}
                        </button>
                    </div>
                </div>
            </Container>
            <Form.Group className="col-12 col-sm-4 my-2">
                <Form.Control
                    type="text"
                    placeholder="Search User by email"
                    onChange={handleSearch}
                />
            </Form.Group>
            <div className="table_wrap">
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>
                                <div className="sort-header">
                                    <span>ID</span>
                                    <span>
                                        <i
                                            className="fa-solid fa-arrow-up-long"
                                            onClick={() =>
                                                handleSort("id", "asc")
                                            }
                                        ></i>
                                        <i
                                            className="fa-solid fa-arrow-down-long"
                                            onClick={() =>
                                                handleSort("id", "desc")
                                            }
                                        ></i>
                                    </span>
                                </div>
                            </th>
                            <th>Email</th>
                            <th>
                                <div className="sort-header">
                                    <span>First Name</span>
                                    <span>
                                        <i
                                            className="fa-solid fa-arrow-up-long"
                                            onClick={() =>
                                                handleSort("first_name", "asc")
                                            }
                                        ></i>
                                        <i
                                            className="fa-solid fa-arrow-down-long"
                                            onClick={() =>
                                                handleSort("first_name", "desc")
                                            }
                                        ></i>
                                    </span>
                                </div>
                            </th>
                            <th>Last Name</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {listUser &&
                            listUser.length > 0 &&
                            listUser.map((user) => {
                                return (
                                    <tr key={`${user.id}`}>
                                        <td>{user.id}</td>
                                        <td>{user.email}</td>
                                        <td>{user.first_name}</td>
                                        <td>{user.last_name}</td>
                                        <td>
                                            <button
                                                style={{ minWidth: "72px" }}
                                                className="btn btn-warning mx-2"
                                                onClick={() =>
                                                    handleShowModalEdit(user)
                                                }
                                            >
                                                Edit
                                            </button>
                                            <button
                                                style={{
                                                    minWidth: "72px",
                                                    marginTop: "5px",
                                                }}
                                                className="btn btn-danger mx-2"
                                                onClick={() =>
                                                    handleShowModalDelete(user)
                                                }
                                            >
                                                Delete
                                            </button>
                                        </td>
                                    </tr>
                                );
                            })}
                    </tbody>
                </Table>
            </div>
            <ReactPaginate
                breakLabel="..."
                nextLabel="next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={totalPage}
                previousLabel="< previous"
                renderOnZeroPageCount={null}
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
            />
            <ModalAddNew
                show={showModalAdd}
                setShowModal={setShowModalAdd}
                handleAddUser={handleAddUser}
            />
            <ModalEditUser
                show={showModalEdit}
                setShowModal={setShowModalEdit}
                dataUserEdit={dataUserEdit}
                handleUpdateUser={handleUpdateUser}
            />
            <ModalDeleteUser
                show={showModalDelete}
                setShowModal={setShowModalDelete}
                dataUserDelete={dataUserEdit}
                handleDeleteUser={handleDeleteUser}
            />
        </>
    );
}

export default TableUsers;
