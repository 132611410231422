// import { Row } from "react-bootstrap";
import "./App.scss";
import Header from "./Components/Header";
import Home from "./Components/Home";
import Container from "react-bootstrap/Container";
import { ToastContainer } from "react-toastify";
import { Route, Routes } from "react-router-dom";
import Feature from "./Components/Feature";
import TableUsers from "./Components/TableUsers";
import { createContext, useState, useEffect } from "react";

export const UserContext = createContext();

function App() {
    const [user, setUser] = useState({ email: "", auth: false });
    const login = (email) => {
        setUser({ email, auth: true });
    };
    const logout = () => {
        setUser({ email: "", auth: false });
    };
    useEffect(() => {
        const preUser = localStorage.getItem("user");
        if (preUser) {
            login(preUser);
        }
    }, []);
    return (
        <>
            <UserContext.Provider value={{ user, login, logout }}>
                <div className="app-container">
                    <Header />
                    <Container>
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/Feature" element={<Feature />} />
                            <Route
                                path="/Manage"
                                element={user.auth ? <TableUsers /> : <Home />}
                            />
                        </Routes>
                    </Container>
                </div>
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
            </UserContext.Provider>
        </>
    );
}

export default App;
