function Feature() {
    return (
        <div className="my-3">
            <h2> Web Feature</h2>
            <p>
                Use API form https://reqres.in/ to build the website.
                <br></br>
                Use React and bootstrap library to build a website has some
                Feature such as:
            </p>
            <ul>
                <li> 1. Login</li>
                <li> 2. Add User</li>
                <li> 3. Update User</li>
                <li> 4. Delete User</li>
                <li> 5. Display all User</li>
                <li> 6. Search user by email</li>
                <li> 7. Sort user by id or FirstName</li>
                <li> 8. Import User from file csv</li>
                <li> 9. EXport User to file csv</li>
                <li> 10. Assign permissions to users</li>
            </ul>
        </div>
    );
}

export default Feature;
