import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useEffect, useState } from "react";

import { toast } from "react-toastify";
import { deleteUser } from "../service/UserService";

function ModalDeleteUser({
    show,
    setShowModal,
    dataUserDelete,
    handleDeleteUser,
}) {
    const [name, setName] = useState("");

    const handleClose = () => {
        setShowModal(false);
    };
    useEffect(() => {
        if (show) {
            setName(dataUserDelete.first_name);
        }
    }, [dataUserDelete, show]);
    const handleDelete = async () => {
        let res = await deleteUser(dataUserDelete.id);
        if (res.status === 204) {
            //success

            handleClose();
            setName("");
            handleDeleteUser(dataUserDelete.id);
            toast.success("success! this user is deleted", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };
    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete user</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <p
                                style={{
                                    fontSize: "20px",
                                }}
                            >
                                <b>Are you sure to delete the user : {name}</b>
                            </p>
                        </Form.Group>

                        <p
                            style={{
                                color: "red",
                                fontSize: "16px",
                            }}
                        >
                            {" "}
                            this user can't restore after you delete
                        </p>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleDelete}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default ModalDeleteUser;
