import axios from "./CustomAxios";

const fetchAllUser = (page) => {
    return axios.get(`/users?page=${page}`);
};
const postCreateUser = (name, job) => {
    return axios.post("/users", { name, job });
};
const putUpdateUser = (name, job, id) => {
    return axios.put(`/users/${id}`, { name, job });
};
const deleteUser = (id) => {
    return axios.delete(`/users/${id}`);
};
const APIlogin = (username, password) => {
    return axios.post(`/login`, { username, password });
};
export { fetchAllUser, postCreateUser, putUpdateUser, deleteUser, APIlogin };
