import { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { fetchAllUser } from "../service/UserService";
import ReactPaginate from "react-paginate";
import { Container } from "react-bootstrap";
import _ from "lodash";
import Form from "react-bootstrap/Form";
import { debounce } from "lodash";
function Home() {
    const [listUser, setListUser] = useState([]);
    // const [totalUser, setTotalUser] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    //

    useEffect(() => {
        //call api
        getUsers();
    }, []);

    //
    const getUsers = async (page) => {
        let res = await fetchAllUser(page);
        if (res && res.data) {
            // setTotalUser(res.total);
            setTotalPage(res.total_pages);
            setListUser(res.data);
        }
    };

    const handlePageClick = (e) => {
        getUsers(+e.selected + 1);
    };

    const handleSort = (sortField, sortType) => {
        let cloneListUser = [...listUser];
        cloneListUser = _.orderBy(cloneListUser, [sortField], [sortType]);
        setListUser(cloneListUser);
    };
    ///search
    const handleSearch = debounce((e) => {
        let term = e.target.value;

        let cloneListUsers = [...listUser];
        if (term) {
            cloneListUsers = cloneListUsers.filter((user) =>
                user.email.includes(term)
            );
            setListUser(cloneListUsers);
        } else {
            getUsers(1);
        }
    }, 1000);
    //// import

    //// export

    return (
        <>
            <Container>
                <div className="table-header my-3">
                    <span>
                        <b>List User</b>
                    </span>
                    <Form.Group className="col-4">
                        <Form.Control
                            type="text"
                            placeholder="Search User by email"
                            onChange={handleSearch}
                        />
                    </Form.Group>
                </div>
            </Container>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>
                            <div className="sort-header">
                                <span>ID</span>
                                <span>
                                    <i
                                        className="fa-solid fa-arrow-up-long"
                                        onClick={() => handleSort("id", "asc")}
                                    ></i>
                                    <i
                                        className="fa-solid fa-arrow-down-long"
                                        onClick={() => handleSort("id", "desc")}
                                    ></i>
                                </span>
                            </div>
                        </th>
                        <th>Email</th>
                        <th>
                            <div className="sort-header">
                                <span>First Name</span>
                                <span>
                                    <i
                                        className="fa-solid fa-arrow-up-long"
                                        onClick={() =>
                                            handleSort("first_name", "asc")
                                        }
                                    ></i>
                                    <i
                                        className="fa-solid fa-arrow-down-long"
                                        onClick={() =>
                                            handleSort("first_name", "desc")
                                        }
                                    ></i>
                                </span>
                            </div>
                        </th>
                        <th>Last Name</th>
                    </tr>
                </thead>
                <tbody>
                    {listUser &&
                        listUser.length > 0 &&
                        listUser.map((user) => {
                            return (
                                <tr key={`${user.id}`}>
                                    <td>{user.id}</td>
                                    <td>{user.email}</td>
                                    <td>{user.first_name}</td>
                                    <td>{user.last_name}</td>
                                </tr>
                            );
                        })}
                </tbody>
            </Table>
            <ReactPaginate
                breakLabel="..."
                nextLabel="next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={totalPage}
                previousLabel="< previous"
                renderOnZeroPageCount={null}
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
            />
        </>
    );
}

export default Home;
