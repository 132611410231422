import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import logo from "../assets/images/logo192.png";
import { NavLink } from "react-router-dom";
import ModalLogin from "./ModalLogin";
import { useState, useContext } from "react";
import { UserContext } from "../App";
function Header(props) {
    const { user, logout } = useContext(UserContext);
    const [showModalLogin, setShowModalLogin] = useState(false);
    return (
        <Navbar expand="lg" className="bg-body-tertiary">
            <Container>
                <NavLink to="/" className="navbar-brand">
                    <img
                        src={logo}
                        alt="logo"
                        width="30"
                        height="30"
                        className="d-inline-block align-top mx-1"
                    />
                    User manager App
                </NavLink>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        {user.auth && (
                            <NavLink className="nav-link" to="/Manage">
                                Manage User
                            </NavLink>
                        )}
                        <NavLink className="nav-link" to="/Feature">
                            Web Feature
                        </NavLink>
                    </Nav>
                    <Nav>
                        {user.email && (
                            <div className="nav-link"> {user.email}</div>
                        )}
                        <NavDropdown title="Action" id="basic-nav-dropdown">
                            {!user.auth ? (
                                <div
                                    className="dropdown-item "
                                    onClick={() => {
                                        setShowModalLogin(true);
                                    }}
                                >
                                    Login
                                </div>
                            ) : (
                                <div
                                    className="dropdown-item "
                                    onClick={() => {
                                        logout();
                                        localStorage.setItem("user", "");
                                    }}
                                >
                                    Logout
                                </div>
                            )}
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Container>
            <ModalLogin
                show={showModalLogin}
                setShowModal={setShowModalLogin}
            />
        </Navbar>
    );
}

export default Header;
